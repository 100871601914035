<template>
    <div class="projectProgress">
        <div class="progress-con">
            <div class="progress-conter" v-if="timeData.length>0">
                <div class="center-axis">
                    <span class="iconfont iconshijianxiao top-icon"></span>
                </div>
                <template v-for="(item, index) in timeData">
                    <div class="progress-item clearfix" :key="`left${item.epid}`" v-if="index%2===0">
                        <div class="progress-item-left">
                            <div class="progress el_left">
                                <div class="describe clearfix">
                                    <span class="icon el_left"></span>
                                    <div class="info el_left">
                                        {{ item.visual_progress }}
                                    </div>
                                </div>
                                <div class="file-con" :class="{'more-file':item.expend}">
                                    <div class="file-con-c">
                                        <div
                                            class="el_left item"
                                            v-for="(img, imgIndex) in item.images_urls"
                                            :key="`${imgIndex}+${img}`"
                                            @click="previewImg(item.images_urls)"
                                        >
                                            <img :src="`${img}`">
                                        </div>
                                    </div>
                                    <div class="el_left more" v-if="item.images_data.length>5" @click="item.expend = !item.expend">
                                        <span>查看更多</span>
                                    </div>
                                </div>
                            </div>
                            <div class="line el_left"></div>
                        </div>
                        <div class="progress-item-right">
                            <div class="report-info">
                                <div class="time">
                                    {{ item.created }}
                                </div>
                                <div class="report-person">
                                    (上报人:{{ item.creator }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-item clearfix progress-item-reverse" :key="`right${item.epid}`" v-if="index%2===1">
                        <div class="progress-item-right">
                            <div class="report-info">
                                <div class="time">
                                    {{ item.created }}
                                </div>
                                <div class="report-person">
                                    (上报人:{{ item.creator }})
                                </div>
                            </div>
                        </div>
                        <div class="progress-item-left">
                            <div class="line el_left"></div>
                            <div class="progress el_left">
                                <div class="describe clearfix">
                                    <span class="icon el_left"></span>
                                    <div class="info el_left">
                                        {{ item.visual_progress }}
                                    </div>
                                </div>
                                <div class="file-con" :class="{'more-file':item.expend}">
                                    <div class="file-con-c">
                                        <div
                                            class="el_left item"
                                            v-for="(img, imgIndex) in item.images_urls"
                                            :key="`${imgIndex}+${img}`"
                                            @click="previewImg(item.images_urls)"
                                        >
                                            <img :src="`${img}`">
                                        </div>
                                    </div>
                                    <div class="el_left more" v-if="item.images_data.length>5" @click="item.expend = !item.expend">
                                        <span>查看更多</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="default" v-else></div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            timeData: [],
            viewMore: false,
            baseUrl: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 拉取工程进度列表
        getEngineeringList() {
            this.$axios
                .get('/interfaceApi/sale/engineering_progress/get_engineering_list/' + this.extr.engineering_id)
                .then(res => {
                    if (res) {
                        res.map(item => {
                            let arr = [];
                            if (item.images_data) {
                                arr = item.images_data.split(',');
                            }
                            item.images_data = arr;
                            item.expend = false;
                        });
                        res.forEach(item => {
                            if (item.images_data && item.images_data.length > 0) {
                                item.images_data.forEach(id => {
                                    this.getFileDetailFun(id, item, res);
                                });
                            }
                        });
                        setTimeout(() => {
                            this.$set(this, 'timeData', res);
                        }, 300);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 预览图片
        previewImg(imgData) {
            this.$toast({
                title: true,
                text: '图片预览',
                type: 'eject',
                width: '15.4rem',
                t_url: 'picturePreview/index',
                extr: {
                    code: {
                        imgSrc: imgData,
                        picturePreview: [],
                    },
                },
                viewPosition: 'components',
            });
        },
        getFileDetailFun(id, item, data) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    data.forEach(val => {
                        if (val.epid === item.epid) {
                            if (val.images_urls) {
                                val.images_urls.push(res.preview_url);
                            } else {
                                val.images_urls = [res.preview_url];
                            }
                        }
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        this.baseUrl = this.FILE.FILE_BASE_URL() + 'file/';
        this.getEngineeringList();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.projectProgress
    height 100%
    padding .2rem 0 .2rem .2rem
    .progress-con
        overflow-y scroll
        overflow-x hidden
        position relative
        height 100%
        .progress-conter
            height auto
            position relative
            min-height: 100%;
            .center-axis
                position absolute
                left 50%
                width 4px
                top .4rem
                background #3B6FBD
                margin-left -2px
                height calc(100% - .4rem)
                .top-icon
                    position absolute
                    top -.38rem
                    font-size .4rem
                    color #3B6FBD
                    left -.18rem
            .progress-item
                padding-top .5rem
                .progress-item-left
                    width 50%
                    position relative
                    float left
                    .progress
                        width calc(100% - 1.96rem)
                        .describe
                            overflow hidden
                            .icon
                                width .26rem
                                height .16rem
                                background url(./../../images/project_icon_red.png) no-repeat
                                background-size 100% 100%
                                margin-top .05rem
                            .info
                                margin-left .14rem
                                width calc(100% - .4rem)
                        .file-con
                            overflow hidden
                            max-height 1.07rem
                            transition max-height 1s
                            .file-con-c
                                height 100%
                                width calc(100% - .83rem)
                                float left
                                .item
                                    margin .1rem
                                    cursor pointer
                                    img
                                        width .73rem
                                        height .73rem
                                        border-radius .08rem
                            .more
                                width .73rem
                                height .73rem
                                background url(./../../images/project_bg.png) no-repeat
                                background-size 100% 100%
                                text-align center
                                line-height .8rem
                                float left
                                margin-top .1rem
                                cursor pointer
                                span
                                    color #D6000F
                                    font-weight 400
                                    font-size .16rem
                        .more-file
                            max-height 8rem
                    .line
                        width 1.96rem
                        height .26rem
                        background url(./../../images/project_line_red.png) no-repeat
                        background-size 100% 100%
                        position absolute
                        right -.14rem
                        top .5rem
                .progress-item-right
                    width 50%
                    position relative
                    float left
                    .report-info
                        position absolute
                        left .3rem
                        top .5rem
                        .time
                            color #D6000F
                            font-size .18rem
                        .report-person
                            color #979EA7
                            font-size .14rem
            .progress-item-reverse
                .progress-item-right
                    height 2rem
                    position relative
                    .report-info
                        position absolute
                        right  .3rem
                        top .5rem
                        text-align right
                        .time
                            color #022782
                        .report-person
                            color #979EA7
                .progress-item-left
                    position relative
                    .line
                        width 1.96rem
                        height .26rem
                        background url(./../../images/project_line_blue.png) no-repeat
                        background-size 100% 100%
                        position absolute
                        left -.14rem
                        top .5rem
                    .progress
                        margin-left 1.96rem
        .default
            background url(./../../images/progess_default.png) no-repeat
            background-position center center
            height 100%
</style>